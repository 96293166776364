export class RestService {
    api: string;

    authorization: string | undefined;

    constructor(api: string, authorization?: string) {
        this.api = api;
        this.authorization = authorization;
    }

    get<T>(
        path: string,
        params?: URLSearchParams
    ): Promise<T> {
        return this.request<T>(
            "GET",
            path,
            undefined,
            params
        );
    }

    delete<T>(
        path: string,
        body?: unknown,
        params?: URLSearchParams
    ): Promise<T> {
        return this.request<T>(
            "DELETE",
            path,
            body,
            params
        );
    }

    post<T>(
        path: string,
        body?: unknown,
        params?: URLSearchParams
    ): Promise<T> {
        return this.request<T>(
            "POST",
            path,
            body,
            params
        );
    }

    put<T>(
        path: string,
        body?: unknown,
        params?: URLSearchParams
    ): Promise<T> {
        return this.request<T>(
            "PUT",
            path,
            body,
            params
        );
    }

    event(type: number, data: unknown, id: number, email?: string): void {
        const time = new Date().toISOString();
        navigator.sendBeacon(
            new URL(`${this.api}/events`),
            new Blob(
                [JSON.stringify({ data, email, id, time, type })],
                { type: "application/json" }
            )
        );
    }

    private async request<T>(
        method: string,
        path: string,
        body?: unknown,
        params?: URLSearchParams
    ): Promise<T> {
        const url = new URL(`${this.api}${path}`);
        url.search = params ? params.toString() : url.search;

        const headers = [["Content-Type", "application/json"]];

        if (this.authorization) {
            headers.push(
                ["Authorization", this.authorization]
            );
        }

        const response = await fetch(
            new Request(url.toString(), {
                body: body ? JSON.stringify(body) : null,
                headers,
                method
            })
        );

        if (response.ok) {
            return response.json();
        }

        // TODO: Add failure handling for network issues
        throw response;
    }
}
