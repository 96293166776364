import { NavLink, NavLinkProps } from "react-router-dom";
import { blue, green, orange, red } from "@mui/material/colors";

import { createTheme } from "@mui/material";
import { forwardRef } from "react";

const LinkBehavior = forwardRef<
    any,
    Omit<NavLinkProps, "to"> & { href: NavLinkProps["to"] }
>((props, ref) => {
    const { href, ...other } = props;
    return <NavLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme({
    // MUI requires these to be in ascending size order.
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1310,
            xl: 1536,
        },
        /* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */
    },
    components: {
        MuiButtonBase: { defaultProps: { LinkComponent: LinkBehavior } },
    },
    palette: {
        info: { main: orange[700] },
        primary: { main: blue[700] },
        secondary: { main: green[700] },
        warning: { main: red[700] },
    },
    typography: {
        body1: {
            color: "var(--dark)",
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "0.4px",
            lineHeight: "1.63",
        },
        fontFamily: ["Lato", "-apple-system", "sans-serif"].join(","),
        fontSize: 16,
        h1: {
            color: "var(--dark)",
            fontSize: "3rem",
            fontStretch: "normal",
            fontStyle: "normal",
            fontWeight: "700",
            letterSpacing: "-0.1px",
            lineHeight: "1.17",
            margin: "16px 0 8px 0",
        },
        h2: {
            color: "var(--dark)",
            fontSize: "1.5rem",
            fontStretch: "normal",
            fontStyle: "normal",
            fontWeight: "700",
            letterSpacing: "normal",
            margin: "8px 0",
        },
        h3: {
            color: "var(--dark)",
            fontSize: "1.25rem",
            fontStretch: "normal",
            fontStyle: "normal",
            fontWeight: "700",
            letterSpacing: "0.22px",
            lineHeight: "1.3",
        },
    },
});
