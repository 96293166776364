import { AuthGroup, AuthMethod } from "../../types/authentication";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { CognitoService } from "../services/cognito";
import { RestProvider } from "./RestContext";

export type FullName = { first: string; last: string };

const AuthContext = createContext(
  {} as {
    user: CognitoUser | undefined;
    setUser: (user: CognitoUser) => void;

    name: FullName | undefined;

    session: CognitoUserSession | undefined;
    updateSession: (session: CognitoUserSession | null) => void;

    authMethod: AuthMethod | null;
    setAuthMethod: (method: AuthMethod) => void;

    isAuthenticated: boolean;

    email: string | null;
    group: AuthGroup | null;
    token: string | undefined;

    clear: () => void;
  }
);

interface AuthProviderProps {
  children: ReactNode;
  user?: CognitoUser | undefined;
  session?: CognitoUserSession | undefined;
  token?: string;
  refresh?: boolean;
}

export const AuthProvider = (props: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<CognitoUser>(props.user!);
  const [session, setSession] = useState<CognitoUserSession>(props.session!);

  const [name, setName] = useState<FullName>();
  const [email, setEmail] = useState("");

  const [token, setToken] = useState(props.token);
  const [group, setGroup] = useState<AuthGroup | null>(null);
  const [authMethod, setAuthMethod] = useState<AuthMethod | null>(null);

  const [isAuthenticated, setAuthenticated] = useState(false);

  const updateSession = (session: CognitoUserSession | null): void => {
    setSession(session! ?? undefined);
    setEmail(session?.getIdToken().decodePayload()["email"] || "");

    if (session?.isValid()) {
      localStorage.removeItem("token");

      setAuthenticated(true);
      const decoded = session?.getIdToken()?.decodePayload();
      setGroup(
        decoded["cognito:groups"]?.filter(
          (group: string) => group !== "staff" && !group.includes('div_')
        )[0]
      );
      setName({
        first: decoded["given_name"],
        last: decoded["family_name"],
      });
    } else {
      setAuthenticated(false);
    }
  };

  const refresh = () => {
    if (!user || !session) {
      return;
    }

    user.refreshSession(session.getRefreshToken(), (error, session) => {
      if (error) {
        setAuthenticated(false);
      } else {
        updateSession(session);
      }
    });
  };

  useEffect(() => {
    const current = CognitoService.pool.getCurrentUser?.();
    
    if (current) {
      current.getSession((error: null, session: CognitoUserSession) => {
        if (error) {
          return;
        }
        setUser(current);
        updateSession(session);
        setAuthMethod("password");
        setAuthenticated(true);
      });
    } else if (token) {
      setGroup("user");
      setAuthMethod("token");
      setAuthenticated(true);
    } else if ("token" in localStorage) {
      const token = localStorage.getItem("token")!;

      setToken(token);
      setGroup("user");
      setAuthMethod("token");
      setAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const timeout = window.setTimeout(refresh, 5 * 60 * 1000);
    return () => window.clearTimeout(timeout);
  }, [session]);

  const clear = () => {
    setGroup(null);
    setEmail("");
    setName(undefined);
    setToken(undefined);
    setAuthMethod(null);
    setAuthenticated(false);
  };

  useEffect(() => {
    if (token) {
      setGroup("user");
      setAuthMethod("token");
      setAuthenticated(true);

      localStorage.setItem("token", token);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authMethod,
        email,
        group,
        session,
        setAuthMethod,
        setUser,
        isAuthenticated,
        token,
        updateSession,
        name,
        user,
        clear,
      }}
    >
      <RestProvider>{props.children}</RestProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
