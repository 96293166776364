import { ReactNode, createContext, useContext } from "react";

import { RestService } from "../services/rest";
import { useAuth } from "./AuthContext";

const RestContext = createContext(
    {} as {
        authorization: string | undefined;

        get: <T>(uri: string, params?: URLSearchParams) => Promise<T>;

        put: <T>(
            uri: string,
            body: unknown,
            params?: URLSearchParams
        ) => Promise<T>;

        post: <T>(
            uri: string,
            body: unknown,
            params?: URLSearchParams
        ) => Promise<T>;

        delete: <T>(
            uri: string,
            body?: unknown,
            params?: URLSearchParams
        ) => Promise<T>;

        event: (type: number, data: unknown, id: number, email?: string) => void;
    }
);

export const RestProvider = (props: { children: ReactNode }): JSX.Element => {
    const { session, token, authMethod } = useAuth();

    const rest = new RestService(
        import.meta.env.APP_API_URL,
        token && authMethod === "token"
            ? token
            : session?.getIdToken()?.getJwtToken()
    );

    return (
        <RestContext.Provider value={rest}>{props.children}</RestContext.Provider>
    );
};

export const useRest = () => useContext(RestContext);
