import "./polyfills";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import * as SentryReact from "@sentry/react";
import * as SentryTracing from "@sentry/tracing";

import { AppRoot } from "./app/components/AppRoot";
import { AuthProvider } from "./app/context/AuthContext";
import { Background } from "./app/design/background";
import { TranslationsService } from "./app/services/translations";
import { theme } from "./app/design/theme";

import "./styles.css";
import { MixpanelService } from "@services/mixpanel";

SentryReact.init({
    dsn: import.meta.env.APP_SENTRY_DSN,
    environment: import.meta.env.MODE,
    enabled: !import.meta.env.DEV,
    debug: !import.meta.env.PROD,
    integrations: [ new SentryTracing.BrowserTracing() ],
    // release: "1.0.0",
});


MixpanelService.init();
TranslationsService.init();

const params = new URLSearchParams(location.search);
history.replaceState(history.state, "", location.pathname);

createRoot(document.getElementById("root") as HTMLDivElement).render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Background>
                <AuthProvider token={params.get("token")!}>
                    <AppRoot />
                </AuthProvider>
            </Background>
        </BrowserRouter>
    </ThemeProvider>
);
