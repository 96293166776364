import {
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

export class CognitoService {
  static readonly pool = new CognitoUserPool({
    UserPoolId: import.meta.env.APP_COGNITO_POOL_ID,
    ClientId: import.meta.env.APP_COGNITO_CLIENT_ID,

    // TODO Discuss with Reece why this breaks refreshes
    // Storage: new CookieStorage({
    //     domain: "mymynd.co.uk",
    //     path: "/"
    // })
  });

  static createUser(username: string): CognitoUser {
    return new CognitoUser({
      Pool: this.pool,
      Username: username.toLowerCase(),
    });
  }
}
