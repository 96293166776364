import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";

import { useAuth } from "../context/AuthContext";
import { MixpanelService } from "@services/mixpanel";

const UserRoot = lazy(() => import("./User/UserRoot"));
const LoginRoot = lazy(() => import("./Login/LoginRoot"));
const AssessmentRoot = lazy(() => import("./Assessment/AssessmentRoot"));
const ResultsRoot = lazy(() => import("./Results/ResultsRoot"));

export const AppRoot = (): JSX.Element => {
  const navigate = useNavigate();
  let location = useLocation();
  const { group } = useAuth();

  useEffect(() => {
    if (group == "user" && location.pathname === "/") {
      navigate(group);
    }
  }, [group]);

  useEffect(() => {
    MixpanelService.pageview();
  }, [location]);

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="user/*" element={<UserRoot />} />
        <Route path="results/*" element={<ResultsRoot />} />
        <Route path="assessment/*" element={<AssessmentRoot />} />
        <Route path="login/*" element={<LoginRoot />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};
