import i18n, { Resource, ResourceKey, ResourceLanguage } from "i18next";

import { initReactI18next } from "react-i18next";
import assessment from "../static/translations.json";

export class TranslationsService {

    static init(): void {

        i18n.use(initReactI18next).init({
            lng: "en",
            resources: TranslationsService.format(
                assessment as ResourceLanguage
            ) as Resource,
        });

    }

    static format(x: ResourceLanguage): ResourceLanguage {

        return Object.fromEntries(
            Object.entries(x).map(([k, v]: [string, ResourceKey]) => [
                k,
                typeof v === "string"
                    ? v.replaceAll("\n\n", "<br/><br/>").replaceAll("\n", "")
                    : TranslationsService.format(v),
            ])
        );

    }
}
