import mixpanel from "mixpanel-browser";

export class MixpanelService {

    private static enabled: boolean;

    static init(): void {
        mixpanel.init(
            import.meta.env.APP_MIXPANEL_TOKEN,
            {
                debug: !import.meta.env.PROD,
                api_host: "https://mp.mymynd.co.uk",
            }
        );
        this.enabled = true;
    }

    static identify(participant: any): void {
        if (!this.enabled) {
            return;
        }

        mixpanel.identify(participant.uuid)
        mixpanel.register({'division_id': [participant.division_id]});
    }

    static pageview(): void {
        if (!this.enabled) {
            return;
        }

        mixpanel.track('page_view')
    }

    static response(question_id: number, next_id: number, proportion: number): void {
        if (!this.enabled) {
            return;
        }

        mixpanel.track('assessment_response', {
            "question_id": question_id,
            "next_question_id": next_id,
            "proportion": proportion
        })
    }

}